import React from 'react';
import {
  generateColumn,
  IDCOLUMN,
  sorterText,
  IDCOLUMNOWNER,
  createdAtColumn,
} from './ColumnUtils';
import dayjs from 'dayjs';
import { BiCheckboxChecked, BiErrorAlt, BiXCircle } from 'react-icons/bi';
import { t } from 'i18next';

import { formatPrice } from '../utils/formatPrice';
// import { CRM_OPTIONS } from '../../services/utils/CRM_OPTIONS';
import { Tag, Tooltip } from 'antd';
import { useCRMOptions } from '../utils/CRM_OPTIONS';
import moment from 'moment/moment';
// import { useTranslation } from 'react-i18next';

export const SalesPerUserColumns = () => {
  return [
    generateColumn(
      t('seller', 'Vendedor'),
      'seller_name',
      '8%',
      false,
      sorterText('seller_name')
    ),
    generateColumn(
      t('total_singular', 'Total'),
      'total_sales',
      '8%',
      (text) => <span>{formatPrice(text)}</span>,
      sorterText('total_sales')
    ),
    generateColumn(
      t('same.moment_singular', 'Mismo momento'),
      'same_moment_sales',
      '8%',
      (text) => (
        <span>{text ? t('yes_singular', 'Si') : t('no_singular', 'No')}</span>
      ),
      {
        filters: [
          {
            text: t('yes_singular', 'Si'),
            value: true,
          },
          {
            text: t('no_singular', 'No'),
            value: false,
          },
        ],
        onFilter: (value, record) => record.same_moment_sales == value,
      }
    ),

    generateColumn(
      t('month_singular', 'Mes'),
      'date_month',
      '8%',
      (text) => <span>{dayjs(text).format('MMMM YYYY')}</span>,
      sorterText('date_month')
    ),
  ];
};

export const SalesPerDayColumns = () => {
  return [
    generateColumn(
      t('date.title', 'Fecha'),
      'date_sale',
      '8%',
      false,
      sorterText('date_sale')
    ),
    generateColumn(
      t('total_singular', 'Total'),
      'total_sales',
      '8%',
      (text) => <span>{formatPrice(text)}</span>,
      sorterText('total_sales')
    ),
  ];
};

export const ReservesPerHourColumns = () => {
  return [
    generateColumn(
      t('hour_singular', 'Horas'),
      'class_hour',
      '8%',
      false,
      sorterText('class_hour')
    ),
    generateColumn(
      t('monday_singular', 'Lunes'),
      '',
      '8%',
      (_, record) => <span>{record.totalsByDayOfWeek.monday}</span>,
      sorterText('class_hour')
    ),
    generateColumn(
      t('tuesday_singular', 'Martes'),
      '',
      '8%',
      (_, record) => <span>{record.totalsByDayOfWeek.tuesday}</span>,
      sorterText('class_hour')
    ),
    generateColumn(
      t('wednesday_singular', 'Miércoles'),
      '',
      '8%',
      (_, record) => <span>{record.totalsByDayOfWeek.wednesday}</span>,
      sorterText('class_hour')
    ),
    generateColumn(
      t('thursday_singular', 'Jueves'),
      '',
      '8%',
      (_, record) => <span>{record.totalsByDayOfWeek.thursday}</span>,
      sorterText('class_hour')
    ),
    generateColumn(
      t('friday_singular', 'Viernes'),
      '',
      '8%',
      (_, record) => <span>{record.totalsByDayOfWeek.friday}</span>,
      sorterText('class_hour')
    ),
    generateColumn(
      t('saturday_singular', 'Sábado'),
      '',
      '8%',
      (_, record) => <span>{record.totalsByDayOfWeek?.saturday}</span>,
      sorterText('class_hour')
    ),
    generateColumn(
      t('sunday_singular', 'Domingo'),
      '',
      '8%',
      (_, record) => <span>{record.totalsByDayOfWeek?.sunday}</span>,
      sorterText('class_hour')
    ),
    generateColumn(
      t('total_singular', 'Total'),
      'total',
      '8%',
      false,
      sorterText('total')
    ),
  ];
};

export const DebitPresentationColumns = () => {
  return [
    IDCOLUMNOWNER,
 
    generateColumn(
      t('first_name', 'Nombre'),
      'first_name',
      '8%',
      false,
      sorterText('first_name')
    ),
    generateColumn(
      t('last_name', 'Apellido'),
      'last_name',
      '8%',
      false,
      sorterText('last_name')
    ),
    generateColumn(
      t('product_singluar', 'Producto'),
      'product_name',
      '10%',
      false,
      sorterText('product_name')
    ),
    generateColumn(
      t('start_singular', 'Inicio'),
      'start_at',
      '7%',
      false,
      sorterText('start_at')
    ),
    generateColumn(
      t('end_singular', 'Fin'),
      'end_at',
      '7%',
      false,
      sorterText('end_at')
    ),
    generateColumn(
      t('users.user', 'Usuario'),
      'username',
      '8%',
      false,
      sorterText('username')
    ),
    generateColumn(
      t('discount', 'Descuento'),
      'discount_name',
      '10%',
      false,
      sorterText('discount_name')
    ),
    generateColumn(
      t('total.discount_singular', 'Total Descuento'),
      'discount',
      '8%',
      (text) => <span>{formatPrice(text)}</span>,
      sorterText('discount')
    ),

    generateColumn(
      t('total_singular', 'Total'),
      'price_debit_payment',
      '8%',
      (text) => <span>{formatPrice(text)}</span>,
      sorterText('price_debit_payment')
    ),
    // generateColumn(
    //   t('sales.payment_method', 'Metodo de pago'),
    //   'payment_methods_name',
    //   '8%',
    //   false,
    //   sorterText('payment_methods_name')
    // ),

    generateColumn(
      t('discharge.reason_singular', 'Motivo Baja'),
      'discharge_reason',
      '8%',
      false,
      sorterText('discharge_reason')
    ),
    generateColumn(
      t('state_singular', 'Estado'),
      'actual_state',
      '8%',
      (text, record) => (
        <Tooltip
          title={
            text == 'active'
              ? // ? t('uploaded.on', {date:dayjs(record.created_at).format('DD/MM/YYYY')})
                `${t('debit.date_charge', 'Se cargó el día')} ${dayjs(
                  record.created_at
                ).format('DD/MM/YYYY')}`
              : text == 'deactivated'
              ? `${('debit.date_discharge', 'Se cargó la baja el día')} ${dayjs(
                  record.updated_at
                ).format('DD/MM/YYYY')}`
              : `${t(
                  'debit.date_inprocess',
                  'Se cargó en el mes en curso, el día'
                )} ${dayjs(record.created_at).format('DD/MM/YYYY')}`
          }
        >
          <div
            className={`rounded-full ${
              text == 'active'
                ? 'bg-lightGreen'
                : text == 'deactivated'
                ? 'bg-primaryRed'
                : 'bg-primaryOrange'
            }`}
          >
            <p
              className={` ${
                text == 'active' ? 'text-darkGreen' : 'text-black'
              } text-center py-1 font-MessinaSansSemiBold`}
            >
              {text == 'active'
                ? t('active', 'Activo')
                : text == 'deactivated'
                ? t('inactive', 'Inactivo')
                : t('new', 'Nuevo')}
            </p>
          </div>
        </Tooltip>
      ),
      {
        filters: [
          {
            text: t('active', 'Activo'),
            value: 'active',
          },
          {
            text: t('inactive', 'Inactivo'),
            value: 'deactivated',
          },
          {
            text: t('new', 'Nuevo'),
            value: 'new',
          },
        ],
        onFilter: (value, record) => record.actual_state == value,
        sorter: (a, b) => (a.actual_state > b.actual_state ? 1 : -1),
      }
    ),
  ];
};

export const MembersCDPColumns = () => {
  return [
    generateColumn(
      t('first_name', 'Nombre'),
      'first_name',
      '7%',
      false,
      sorterText('first_name')
    ),
    generateColumn(
      t('last_name', 'Apellido'),
      'last_name',
      '7%',
      false,
      sorterText('last_name')
    ),
    generateColumn(
      t('day_singular', 'Dia'),
      'class_date',
      '6%',
      (text, record) => (
        <span>
          {dayjs
            .utc(`${record.class_date}${record.class_hour}`)
            // .tz(record.time_zone)
            .format('DD/MM/YYYY')}
        </span>
      ),
      sorterText('class_date')
    ),
    generateColumn(
      t('hour_singular', 'Hora'),
      '',
      '5%',
      (text, record) => (
        <span>
          {dayjs
            .utc(`${record.class_date}${record.class_hour}`)
            // .tz(record.time_zone)
            .format('HH:mm')}
        </span>
      ),
      {
        sorter: (a, b) =>
          dayjs(a.scheduled_at).tz(a.time_zone).format('HH:mm') >
          dayjs(b.scheduled_at).tz(b.time_zone).format('HH:mm')
            ? 1
            : -1,
      }
    ),
    generateColumn(
      t('attendance', 'Asistencia'),
      'has_attended',
      '7%',
      (text) => (
        <span
          className={`${
            text ? 'text-darkGreen' : 'text-primaryRed'
          } font-MessinaSansSemiBold`}
        >
          {text
            ? t('reserves.present', 'Presente')
            : t('reserves.absent', 'Ausente')}
        </span>
      ),
      {
        filters: [
          {
            text: t('reserves.present', 'Presente'),
            value: true,
          },
          {
            text: t('reserves.absent', 'Ausente'),
            value: false,
          },
        ],
        onFilter: (value, record) => record.has_attended == value,
      }
    ),

    generateColumn(
      t('assign_coach', 'Coach asignado'),
      'coach_cdp',
      '8%',
      false,
      sorterText('coach_cdp')
    ),
    generateColumn(
      t('converted', 'Convertido'),
      'actual_prospect_condition',
      '7%',
      (text) => (
        <span
          className={`${
            text == 'Active' ? 'text-darkGreen' : 'text-primaryRed'
          } font-MessinaSansSemiBold`}
        >
          {text == 'Active' ? t('yes_singular', 'Si') : t('no_singular', 'No')}
        </span>
      ),
      {
        filters: [
          {
            text: t('yes_singular', 'Si'),
            value: 'Active',
          },
          {
            text: t('no_singular', 'No'),
            value: 'Inactive',
          },
        ],
        onFilter: (value, record) => record.actual_prospect_condition == value,
      }
    ),

    generateColumn(
      t('firstBuy', 'Primera compra'),
      'first_buy',
      '6%',
      (text)=>text ?moment(text).format('DD/MM/YYYY'): <BiXCircle className='self-center text-center' size={29} color={'#ED4C5C'} />,
      sorterText('first_buy')
    ),
    generateColumn(
      t('previousContact', 'Contacto previo'),
      'previus_contact_class',
      '7%',
      (text, record) => (
        <span>
          {text == 'Contact' ? (
            <Tooltip
              title={t(
                'previousContactDescPositive',
                'El socio tuvo contacto previo'
              )}
            >
              <BiCheckboxChecked size={29} color='#1EA05A' />
            </Tooltip>
          ) : dayjs
              .utc(`${record.class_date}${record.class_hour}`)
              .isAfter(dayjs.utc(dayjs())) ? (
            <Tooltip
              title={t(
                'previousContactDescNegative',
                'El socio aún no cuenta con contacto previo'
              )}
            >
              {' '}
              <BiErrorAlt size={29} color='#FFD500' />
            </Tooltip>
          ) : (
            <Tooltip
              title={t(
                'previousContactDescNone',
                'El socio no tuvo contacto previo'
              )}
            >
              <BiXCircle size={29} color={'#ED4C5C'} />
            </Tooltip>
          )}
        </span>
      ),
      {
        sorter: (a, b) =>
          a.previus_contact_class > b.previus_contact_class ? 1 : -1,
        filters: [
          {
            text: t('contact', 'Contacto'),
            value: 'Contact',
          },
          {
            text: t('noContact', 'Sin contacto'),
            value: 'No Contact',
          },
        ],
        onFilter: (value, record) =>
          value == record.previus_contact_class.trim(),
      }
    ),
    generateColumn(
      t('postContact', 'Contacto posterior'),
      'post_contact_class',
      '7%',
      (text,record) => (
        <span>
          {text == 'Contact' ? (
            <Tooltip
              title={t(
                'postContactDescPositive',
                'El socio tuvo contacto post'
              )}
            >
              <BiCheckboxChecked size={29} color='#1EA05A' />
            </Tooltip>
          ) : dayjs
          .utc(dayjs())
          .diff(`${record.class_date}${record.class_hour}`,'days') < 3 ?
          <Tooltip
              title={t(
                'no_yet_post_contact_coach',
              'Aún no se realizó el contacto del coach después de la Clase de Prueba'
              )}
            >
              <BiXCircle size={29} color={'#FFD500'} />
            </Tooltip> : (
            <Tooltip
              title={t(
                'not_post_contact_coach',
                'No tuvo contacto del coach después de la Clase de Prueba'
              )}
            >
              <BiXCircle size={29} color={'#ED4C5C'} />
            </Tooltip>
          )}
        </span>
      ),
      {
        sorter: (a, b) =>
          a.post_contact_class > b.post_contact_class ? 1 : -1,
        filters: [
          {
            text: t('contact', 'Contacto'),
            value: 'Contact',
          },
          {
            text: t('noContact', 'Sin contacto'),
            value: 'No Contact',
          },
        ],
        onFilter: (value, record) => value == record.post_contact_class.trim(),
      }
    ),
    generateColumn(
      t('prospect.source', 'Fuente'),
      'channel',
      '7%',
      false,
      sorterText('channel')
    ),
    generateColumn(
      t('seller', 'Vendedor'),
      'seller',
      '7%',
      false,
      sorterText('seller')
    ),
  ];
};

export const ExpirationsColumns = () => {
  return [
    IDCOLUMNOWNER,
    generateColumn(
      t('member', 'Socio'),
      '',
      '6%',
      (text, record) => (
        <span>{record.first_name + ' ' + record.last_name}</span>
      ),
      {
        sorter: (a, b) =>
          a.first_name + ' ' + a.last_name > b.first_name + ' ' + b.last_name
            ? 1
            : -1,
      }
    ),
    generateColumn(
      t('product_singluar', 'Producto'),
      'product_name',
      '9%',
      (text) => <span className='text-center'>{text}</span>,
      sorterText('product_name')
    ),
    generateColumn(
      t('start_singular', 'Inicio'),
      'membership_start_date',
      '7%',
      false,
      sorterText('membership_start_date')
    ),
    generateColumn(
      t('expiring_singular', 'Vencimiento'),
      'membership_expiration_date',
      '7%',
      false,
      sorterText('membership_expiration_date')
    ),
    generateColumn(
      t('product.days_left', 'Dias Restantes'),
      'days_left',
      '7%',
      false,
      sorterText('days_left')
    ),
    generateColumn(
      t('product.sessions_left', 'Sesiones restantes'),
      'sessions_left',
      '7%',
      false,
      sorterText('sessions_left')
    ),
    generateColumn(
      t('lastReserve_singular', 'Última reserva'),
      '',
      '6%',
      (text, record) => (
        <span>
          {record.last_reserve &&
            dayjs(record.last_reserve).format('DD/MM/YYYY')}
        </span>
      ),
      {
        sorter: (a, b) => (a.last_reserve > b.last_reserve ? 1 : -1),
        filters: [
          {
            text: t('yes_singular', 'Si'),
            value: true,
          },
          {
            text: t('no_singular', 'No'),
            value: false,
          },
        ],
        onFilter: (value, record) =>
          value ? record.last_reserve : !record.last_reserve,
      }
    ),
    generateColumn(
      t('renovationStart_singular', 'Inicio renovación'),
      'start_date_renovation',
      '8%',
      (t)=> t ? <span>{t}</span> :  <BiXCircle className='self-center text-center' size={29} color={'#ED4C5C'} /> ,
      {
        sorter: {
          sorter: (a, b) =>
            a.start_date_renovation > b.start_date_renovation ? 1 : -1,
        },
        filters: [
          {
            text: t('yes_singular', 'Si'),
            value: true,
          },
          {
            text: t('no_singular', 'No'),
            value: false,
          },
        ],
        onFilter: (value, record) =>
          value ? record.start_date_renovation : !record.start_date_renovation,
      }
    ),
    generateColumn(
      t('isDebit_singular', 'Es Debito'),
      'recurrent_product',
      '6%',
      (text) => (
        <span>
          {text == true ? <BiCheckboxChecked size={29} color='#1EA05A' /> :  <BiXCircle size={29} color={'#ED4C5C'} />}
        </span>
      ),
      {
        sorter: {
          sorter: (a, b) =>
            a.recurrent_product > b.recurrent_product ? 1 : -1,
        },
        filters: [
          {
            text: t('yes_singular', 'Si'),
            value: true,
          },
          {
            text: t('no_singular', 'No'),
            value: false,
          },
        ],
        onFilter: (value, record) =>
          value ? record.recurrent_product : !record.recurrent_product,
      }
    ),
    generateColumn(
      t('dischargeReason_singular', 'Motivo Baja'),
      'discharge_reason',
      '6%',
      false,
      sorterText('discharge_reason')
    ),

    generateColumn(
      t('state_singular', 'Estado'),
      'reserves_31_days',
      '5%',
      false,
      sorterText('reserves_31_days')
    ),
    generateColumn(
      t('coach_singular', 'Coach'),
      'coach_assigned',
      '7%',
      false,
      sorterText('coach_assigned')
    ),
  ];
};

export const MembersCrmsColumns = () => {
  return [
    generateColumn(t('ID_singular', 'ID'), 'customer_id', '5%', false, {
      sorter: (a, b) => a.customer_id - b.customer_id,
      defaultSortOrder: 'ascend',
    }),
    generateColumn(
      t('first_name', 'Nombre'),
      'first_name',
      '6%',
      false,
      sorterText('first_name')
    ),
    generateColumn(
      t('last_name', 'Apellido'),
      'last_name',
      '6%',
      false,
      sorterText('last_name')
    ),
    generateColumn('Fecha', 'crm_date', '7%', false, sorterText('crm_date')),
    generateColumn(
      t('users.user', 'Usuario'),
      'username',
      '7%',
      false,
      sorterText('username')
    ),
    generateColumn('Tipo', 'crm_type', '5%', false, {
      sorter: (a, b) => (a.crm_type > b.crm_type ? 1 : -1),
      onFilter: (value, record) => record.crm_type == value,
    }),
    generateColumn(
      t('messsage', 'Mensaje'),
      'crm_message',
      '20%',
      (text) => (
        <div>
          {text?.split('\n')?.map((item, i) => (
            <p key={i}>{item}</p>
          ))}
        </div>
      ),
      sorterText('crm_message')
    ),
    generateColumn(
      t('contact_type', 'Tipo contacto'),
      'contact_type',
      '7%',
      (text) => <span>{t(text)}</span>,
      {
        ...sorterText('contact_type'),
        filters: [
          {
            text: t('coach_assigned_contact'),
            value: t('coach_assigned_contact'),
          },
          {
            text: t('other_contact'),
            value: t('other_contact'),
          },
        ],
        onFilter: (value, record) => t(record.contact_type) == value,
      }
    ),
  ];
};

export const IndicatorsCrmColumns = () => {
  const CRM_OPTIONS = useCRMOptions();
  // console.log('CRM_OPTIONS', CRM_OPTIONS);
  // const CRM_OPTIONS = useCRMOptions();
  // console.log('CRM_OPTIONS',CRM_OPTIONS);
  const generateCrmColumns = () => {
    return CRM_OPTIONS.filter(
      (option) =>
        option.title !== t('crm.others', 'Otros') &&
        option.title !== t('sales.title_singular', 'Venta')
    ).map((type) => {
      return generateColumn(
        type.title,
        'crm_count_' + type.action_type_id,
        '5%',
        false,
        {
          sorter: (a, b) =>
            a['crm_count_' + type.action_type_id] >
            b['crm_count_' + type.action_type_id]
              ? 1
              : -1,
        }
      );
    });
  };
  const arr = [
    generateColumn(
      t('users.user', 'Usuario'),
      'username',
      '7%',
      false,
      sorterText('username')
    ),
    ...generateCrmColumns(CRM_OPTIONS),
    generateColumn(
      t('total_singular', 'Total'),
      '',
      '6%',
      (text, record) => {
        const sum = [
          record.crm_count_1,
          record.crm_count_4,
          record.crm_count_5,
          record.crm_count_2,
          record.crm_count_3,
          record.crm_count_6,
        ]
          .filter((value) => value)
          .reduce((acc, curr) => acc + curr, 0);

        return <span>{sum}</span>;
      },
      {
        sorter: (a, b) => {
          const sumA = [
            a.crm_count_1,
            a.crm_count_4,
            a.crm_count_5,
            a.crm_count_2,
            a.crm_count_3,
            a.crm_count_6,
          ]
            .filter((value) => value)
            .reduce((acc, curr) => acc + curr, 0);
          const sumB = [
            b.crm_count_1,
            b.crm_count_4,
            b.crm_count_5,
            b.crm_count_2,
            b.crm_count_3,
            b.crm_count_6,
          ]
            .filter((value) => value)
            .reduce((acc, curr) => acc + curr, 0);
          return sumA - sumB;
        },
      }
    ),
  ];
  return arr;
};

export const transactionsColumns = () => {
  return [
    // ...commonColumns,
    generateColumn(
      t('sale.titleID', 'Venta ID'),
      'sale_id',
      '5%',
      false,
      sorterText('seller_name')
    ),
    generateColumn(
      t('sale.titleSale', 'Tipo Venta'),
      'type',
      '5%',
      (text) => (
        <span>
          {text == 'sale'
            ? t('sales.title_singular', 'Venta')
            : text == 'credit_notes'
            ? t('sale.filter.creditNote', 'Nota de credito')
            : text == 'refunds'
            ? t('sale.filter.refund', 'Devolucion')
            : text == 'payments'
            ? t('payments.title_singular', 'Pago')
            : text == 'transaction'
            ? t('transactions.title', 'Transaccion')
            : text}
        </span>
      ),
      {
        sorter: (a, b) => (a.type > b.type ? 1 : -1),
        filters: [
          {
            text: t('sales.title_singular', 'Venta'),
            value: 'sale',
          },
          {
            text: t('sale.filter.creditNote', 'Nota de credito'),
            value: 'credit_notes',
          },
          {
            text: t('sale.filter.refund', 'Devolucion'),
            value: 'refunds',
          },
          {
            text: t('payments.title_singular', 'Pago'),
            value: 'payments',
          },
          {
            text: t('transactions.title', 'Transaccion'),
            value: 'transaction',
          },
        ],
        onFilter: (value, record) => record.type == value,
      }
    ),
    generateColumn(
      t('member', 'Socio'),
      'member_name',
      '8%',
      false,
      sorterText('member_name')
    ),
    generateColumn(
      t('type', 'Tipo'),
      'customer_type',
      '5%',
      (text) => <span>{text == 'Member' ? 'Socio' : 'Prospect'}</span>,
      sorterText('customer_type')
    ),

    generateColumn(
      t('member.id_singular', 'Socio ID'),
      'member_id_eye',
      '5%',
      false,
      sorterText('member_id_eye')
    ),
    // generateColumn(
    //   t('member.manager.id_singular','Socio ID Manager'),
    //   'legacy_member_id',
    //   '5%',
    //   false,
    //   sorterText('legacy_member_id')
    // ),

    generateColumn(
      t('description', 'Descripción'),
      'description',
      '8%',
      false,
      sorterText('description')
    ),
    generateColumn(
      t('category', 'Categoria'),
      'category',
      '8%',
      false,
      sorterText('category')
    ),

    //  product_type_column,
    generateColumn(
      t('seller', 'Vendedor'),
      'seller_name',
      '8%',
      false,
      sorterText('seller_name')
    ),
    generateColumn(
      t('method_singular', 'Metodo'),
      'payment_methods_name',
      '8%',
      false,
      sorterText('payment_methods_name')
    ),

    generateColumn(
      t('quantity_singluar', 'Cantidad'),
      'quantity',
      '5%',
      false,
      sorterText('quantity')
    ),
    generateColumn(
      t('subtotal_singlular', 'Sub Total'),
      'subtotal',
      '7%',
      (text) => <span>{formatPrice(text)}</span>,
      sorterText('subtotal')
    ),
    generateColumn(
      t('discount', 'Descuento'),
      'discount_name',
      '7%',
      false,
      sorterText('discount_name')
    ),
    generateColumn(
      t('total_singular', 'Total'),
      'total',
      '7%',
      (text) => <span>{formatPrice(text)}</span>,
      sorterText('total')
    ),
    generateColumn(
      t('date.title', 'Fecha'),
      'date',
      '5%',
      false,
      sorterText('date')
    ),
    generateColumn(
      t('coach_singular', 'Coach'),
      'coach_name',
      '7%',
      false,
      sorterText('coach_name')
    ),

    // createdAtColumn,
  ];
};

export const DiscountDetailsColumns = () => {
  return [
    generateColumn(
      t('discount', 'Descuento'),
      'discount_name',
      '8%',
      false,
      sorterText('discount_name')
    ),
    generateColumn(
      t('percentage_singular', 'Porcentaje'),
      'percentaje',
      '8%',
      (text) => <span>{parseFloat(text)}%</span>,
      sorterText('percentaje')
    ),

    generateColumn(
      t('quantity_singluar', 'Cantidad'),
      'quantity_discount',
      '8%',
      false,
      sorterText('quantity_discount')
    ),
    generateColumn(
      t('total_singular', 'Total'),
      'total_discount',
      '8%',
      (text) => <span>{formatPrice(text)}</span>,
      sorterText('total_discount')
    ),
    generateColumn(
      t('month_singular', 'Mes'),
      'date_month',
      '8%',
      (text) => <span>{dayjs(text).format('MMMM YYYY')}</span>,
      sorterText('date_month')
    ),
  ];
};
export const LastContactColumns = () => {
  return [
    IDCOLUMNOWNER,
    generateColumn(
      t('first_name', 'Nombre'),
      'first_name',
      '7%',
      false,
      sorterText('first_name')
    ),
    generateColumn(
      t('last_name', 'Apellido'),
      'last_name',
      '7%',
      false,
      sorterText('last_name')
    ),
    generateColumn(
      t('coach_singular', 'Coach'),
      'coach_name',
      '7%',
      false,
      sorterText('coach_name')
    ),
    generateColumn(
      t('product.type_singular', 'Tipo producto'),
      'member_type',
      '7%',
      false,
      {
        sorter: {
          sorter: (a, b) => (a.member_type > b.member_type ? 1 : -1),
        },
      }
    ),
    generateColumn(
      t('product_singluar', 'Producto'),
      'product_name',
      '8%',
      false,
      sorterText('product_name')
    ),
    generateColumn(
      t('product.days_left', 'Dias Restantes'),
      'days_left',
      '5%',
      false,
      sorterText('days_left')
    ),

    generateColumn(
      t('last.contact_singluar', 'Ult. Contacto'),
      'last_contact_coach',
      '7%',
      (text, record) => (
        <span>
          {text && dayjs(text).tz(record.time_zone).format('DD/MM/YYYY')}
        </span>
      ),
      sorterText('last_contact_coach')
    ),
    generateColumn(
      t('days.last.contact_singular', 'Dias Ult. Contacto'),
      'days_last_contact',
      '7%',
      (text, record) => <span>{text && parseFloat(text)}</span>,
      sorterText('days_last_contact')
    ),
    generateColumn(
      t('last.reserve_singular', 'Ult. Reserva'),
      'last_reserve',
      '7%',
      (text, record) => (
        <span>
          {text && dayjs(text).tz(record.time_zone).format('DD/MM/YYYY')}
        </span>
      ),
      sorterText('last_reserve')
    ),
    generateColumn(
      t('state_singular', 'Estado'),
      'member_state',
      '7%',
      (text) => <span>{t(text, text)}</span>,
      sorterText('member_state')
    ),
    generateColumn(
      t('situtation_singular', 'Situacion'),
      'categoria_contacto_socio',
      '7%',
      false,
      {
        ...sorterText('categoria_contacto_socio'),
        filters: [
          {
            text: t('weekly_singular', 'Semanal'),
            value: 'Semanal',
          },
          {
            text: t('monthly_singular', 'Mensual'),
            value: 'Mensual',
          },
          {
            text: t('biweekly_singular', 'Quincenal'),
            value: 'Quincenal',
          },
        ],
        onFilter: (value, record) => record.categoria_contacto_socio == value,
      }
    ),
  ];
};

export const HourspercoachColumns = () => {
  return [
    generateColumn(
      t('coach_singlular', 'Coach'),
      'coach_name',
      '7%',
      false,
      sorterText('coach_name')
    ),
    generateColumn(
      t('location_singular', 'Sede'),
      'location_name',
      '7%',
      false,
      sorterText('location_name')
    ),
    generateColumn(
      t('data.title', 'Fecha'),
      'date_month',
      '7%',
      (text) => <span>{dayjs(text).format('MMMM YYYY')}</span>,
      sorterText('date_month')
    ),
    generateColumn(
      t('hours', 'Horas'),
      'hours',
      '7%',
      false,
      sorterText('hours')
    ),
    generateColumn(
      t('present_singular', 'Asistio'),
      'has_attended',
      '7%',
      (text) => (
        <span
          className={`${
            text ? 'text-darkGreen' : 'text-primaryRed'
          } font-MessinaSansSemiBold`}
        >
          {text
            ? t('reserves.present', 'Presente')
            : t('reserves.absent', 'Ausente')}
        </span>
      ),
      {
        filters: [
          {
            text: t('reserves.present', 'Presente'),
            value: true,
          },
          {
            text: t('reserves.absent', 'Ausente'),
            value: false,
          },
        ],
        onFilter: (value, record) => record.has_attended == value,
      }
    ),
  ];
};

export const refundsColumns = () => {
  return [
    generateColumn(
      t('sale.titleID', 'Venta ID'),
      'sale_id',
      '5%',
      false,
      sorterText('sale_id')
    ),
    generateColumn(
      t('sale.saleDate', 'Venta Fecha'),
      'sale_created_at',
      '5%',
      (text, record) => (
        <span>
          {dayjs(text.created_at).tz(record.time_zone).format('DD/MM/YYYY')}
        </span>
      ),
      sorterText('sale_created_at')
    ),

    generateColumn(
      t('payment.id_singular', 'Pago ID'),
      'payment_id',
      '5%',
      false,
      sorterText('payment_id')
    ),
    generateColumn(
      t('users.user', 'Usuario'),
      'user_name',
      '5%',
      false,
      sorterText('user_name')
    ),
    generateColumn(
      t('member', 'Socio'),
      'customer_name',
      '7%',
      false,
      sorterText('customer_name')
    ),
    generateColumn(
      t('total_singular', 'Total'),
      'amount',
      '5%',
      (text, record) => <span>{formatPrice(text, record.currency)}</span>,
      sorterText('amount')
    ),

    generateColumn(
      t('sales.payment_method', 'Metodo de pago'),
      'payment_method',
      '7%',
      false,
      sorterText('payment_method')
    ),
    generateColumn(
      t('discharge.reason_singular', 'Motivo devolución'),
      'discharge_reason',
      '7%',
      false,
      sorterText('discharge_reason')
    ),

    generateColumn(
      t('created_singular', 'Creado'),
      '',
      '7%',
      (text, record) => (
        <span>
          {dayjs(record.created_at).tz(record.time_zone).format('DD/MM/YYYY')}
        </span>
      ),
      {
        sorter: (a, b) => (dayjs(a.created_at) - dayjs(b.created_at) ? 1 : -1),
      }
    ),
  ];
};

export const creditNotesColumns = () => {
  return [
    generateColumn(
      t('sale.titleID', 'Venta ID'),
      'sale_id',
      '5%',
      false,
      sorterText('sale_id')
    ),
    generateColumn(
      t('sale.saleDate', 'Venta Fecha'),
      'sale_created_at',
      '5%',
      (text, record) => (
        <span>{dayjs(text).tz(record.time_zone).format('DD/MM/YYYY')}</span>
      ),
      sorterText('sale_created_at')
    ),
    generateColumn(
      t('seller', 'Vendedor'),
      'user_name',
      '5%',
      false,
      sorterText('user_name')
    ),
    generateColumn(
      t('member', 'Socio'),
      'customer_name',
      '7%',
      false,
      sorterText('customer_name')
    ),
    generateColumn(
      t('total_singular', 'Total'),
      'amount',
      '5%',
      (text, record) => <span>{formatPrice(text, record.currency)}</span>,
      sorterText('amount')
    ),
    generateColumn(
      t('discharge.reason_singular', 'Motivo devolución'),
      'discharge_reason',
      '7%',
      false,
      sorterText('discharge_reason')
    ),

    generateColumn(
      t('created_singular', 'Creado'),
      '',
      '7%',
      (text, record) => (
        <span>
          {dayjs(record.created_at).tz(record.time_zone).format('DD/MM/YYYY')}
        </span>
      ),
      {
        sorter: (a, b) => (dayjs(a.created_at) - dayjs(b.created_at) ? 1 : -1),
      }
    ),
  ];
};
export const InterusosColumns = () => {
  return [
    generateColumn(
      t('member.id_singular', 'Socio ID'),
      'member_id',
      '5%',
      false,
      sorterText('member_id')
    ),
    generateColumn(
      t('member', 'Socio'),
      'member_name',
      '5%',
      false,
      sorterText('member_name')
    ),
    generateColumn(
      t('member.location_singular', 'Sede socio'),
      'member_location',
      '6%',
      false,
      sorterText('member_location')
    ),
    generateColumn(
      t('gym_classes.title_singular', 'Clase'),
      'gym_class',
      '5%',
      false,
      sorterText('gym_class')
    ),
    generateColumn(
      t('member.location.reserve_singular', 'Sede reserva'),
      'member_location_reserve',
      '5%',
      false,
      sorterText('member_location_reserve')
    ),

    generateColumn(t('reserves_singular', 'Reservas'), 'reserves', '5%', false),
    generateColumn(
      t('reserves.cap_singular', 'Reservas CAP'),
      'reserves_cap',
      '5%',
      false
    ),
    generateColumn(t('month_singular', 'Mes'), 'date_month', '7%', (text) => (
      <span>{dayjs(text).format('MMMM YYYY')}</span>
    )),
  ];
};
export const CC_Columns = () => {
  return [
    generateColumn(
      t('member.id_singular', 'Socio ID'),
      'customer_id',
      '5%',
      false,
      sorterText('customer_id')
    ),
    generateColumn(
      t('member', 'Socio'),
      'customer_name',
      '5%',
      false,
      sorterText('customer_name')
    ),
    generateColumn(
      t('sale.titleID', 'Venta ID'),
      'sale_id',
      '5%',
      false,
      sorterText('sale_id')
    ),
    generateColumn(
      t('seller', 'Vendedor'),
      'seller_name',
      '5%',
      false,
      sorterText('seller_name')
    ),

    generateColumn(
      t('product.id_singular', 'Producto ID'),
      'product_id',
      '5%',
      false,
      sorterText('product_id')
    ),
    generateColumn(
      t('product_singular', 'Producto'),
      'product_name',
      '10%',
      false,
      sorterText('product_name')
    ),
    generateColumn(
      t('total.sale_singular', 'Venta Total'),
      'total_sale',
      '8%',
      (text) => <span>{formatPrice(text)}</span>,
      sorterText('total_sale')
    ),
    generateColumn(
      t('nc.total_singular', 'N/C total'),
      'total_credit_notes',
      '8%',
      (text) => <span>{formatPrice(text)}</span>,
      sorterText('total_credit_notes')
    ),
    generateColumn(
      t('total.refunds_singular', 'Devolución total'),
      'total_refunds',
      '8%',
      (text) => <span>{formatPrice(text)}</span>,
      sorterText('total_refunds')
    ),

    generateColumn(
      t('total.payment_singular', 'Pago Total'),
      'total_payments',
      '8%',
      (text) => <span>{formatPrice(text)}</span>,
      sorterText('total_payments')
    ),
    generateColumn(
      t('total.debt_singular', 'Deuda total'),
      'total_debt',
      '8%',
      (text) => <span>{formatPrice(text)}</span>,
      sorterText('total_debt')
    ),
  ];
};

export const activation_and_desactivations_columns = () => {
  return [
    generateColumn(
      'Mes',
      'start_date',
      '6%',
      (text) => <span>{dayjs(text).format('MMMM YYYY')}</span>,
      sorterText('start_date')
    ),

    generateColumn(
      t('activations_debits', 'Activados'),
      'activations_debits',
      '5%',
      (text) => <span className={`${text == 0 ? 'text-primaryYellow': 'text-primaryGreen'}`}>{text}</span>,
      sorterText('activations_debits')
    ),

    generateColumn(
      t('desactivations_debits', 'Desactivados'),
      'desactivations_debits',
      '5%',
      (text) => <span className='text-primaryRed'>{text}</span>,
      sorterText('desactivations_debits')
    ),
    generateColumn(
      t('net_debits', 'Neto'),
      'net_debits',
      '5%',
       (text) => <span className={`${text  > 0 ? 'text-primaryGreen': text == 0 ?'text-primaryYellow': 'text-primaryRed'}`}>{text}</span>,
      sorterText('net_debits')
    ),
    generateColumn(
      t('saldo_acumulado', 'Saldo acumulado'),
      'saldo_acumulado',
      '5%',
      (t)=> <span>{parseFloat(t)}</span>,
      sorterText('saldo_acumulado')
    ),
    generateColumn(
      t('retention', 'Retención'),
      'retention',
      '6%',
      (text) => <span>{parseFloat(text)}%</span>,
      sorterText('retention')
    ),
    generateColumn(
      'Churn',
      'churn',
      '6%',
      (text) => <span>{parseFloat(text)}%</span>,
      sorterText('churn')
    ),
  ];
};
export const evolution_reseves_active = (start, end) => {
  if (!start || !end) return [];
  let arrMonths = Array.from({ length: end.diff(start, 'months') }, (_, i) => {
    return dayjs(start).add(i, 'month').format('MMMM YYYY');
  });
  let monthColumns = arrMonths.map((month) => {
    return generateColumn(month, month, '7%', false);
  });
  return [
    generateColumn(
      t('member.id_singular', 'Socio ID'),
      'member_id',
      '10%',
      false,
      sorterText('member_id')
    ),
    generateColumn(
      t('member', 'Socio'),
      'member_name',
      '10%',
      false,
      sorterText('member_name')
    ),
    generateColumn(
      t('coach_singular', 'Coach'),
      'coach_name',
      '10%',
      false,
      sorterText('coach_name')
    ),
    ...monthColumns,
    generateColumn(
      t('state_singular', 'Estado'),
      'actual_state',
      '10%',
      false,
      sorterText('actual_state')
    ),
    // generateColumn(
    //  'Asistencia',
    //    'has_attended',
    //    '6%',
    //    false,
    //    sorterText('has_attended')
    //  ),
  ];
};

export const NewAccountsColumns = () => {
  return [
    generateColumn(
      t('member.id_singular', 'Socio ID'),
      'customer_id',
      '5%',
      false,
      sorterText('customer_id')
    ),
    generateColumn(
      t('member', 'Socio'),
      'member_name',
      '8%',
      false,
      sorterText('member_name')
    ),
    generateColumn(
      t('seller', 'Vendedor'),
      'seller_name',
      '8%',
      false,
      sorterText('seller_name')
    ),

    generateColumn(
      t('product.type_singular', 'Tipo producto'),
      'product_type',
      '5%',
      false,
      sorterText('product_type')
    ),
    generateColumn(
      t('new_type', 'Tipo de alta'),
      'type',
      '5%',
      (text) => <span>{text == 'new' ? t('new_converted','Nueva alta'): t('re_registration','Re matriculado') }</span>,
      sorterText('type')
    ),
    generateColumn(
      t('converted', 'Convertido'),
      'convertion_date',
      '5%',
      (text) => dayjs(text).format('DD/MM/YYYY'),
      sorterText('convertion_date')
    ),
  ];
};

export const AssignmentsCoachColumns = () => {
  return [
    generateColumn(
      t('coach_singular', 'Coach'),
      'coach_name',
      '7%',
      false,
      sorterText('coach_name')
    ),
    generateColumn(
      t('member.id_singular', 'Socio ID'),
      'member_id',
      '4%',
      false,
      sorterText('member_id')
    ),
    generateColumn(
      t('member', 'Socio'),
      'member_name',
      '8%',
      false,
      sorterText('member_name')
    ),
    generateColumn(
      t('member_frecuency', 'Frecuencia de contacto'),
      'member_period',
      '7%',
      false,
      sorterText('member_period')
    ),
    generateColumn(
      t('antiquity', 'Antiguedad'),
      'antiquity',
      '7%',
      false,
      sorterText('antiquity')
    ),

    generateColumn(
      t('uses', 'Usos'),
      'total_uses',
      '6%',
      (text, record) => {
     
        return (
          <span
            className={`${text < 6 ? 'text-primaryRed' :  text < 8 ? 'text-primaryYellow': 'text-primaryGreen'}`}
          >
            {text}
          </span>
        );
      },
     
    ),
  ];
};
export const reporthq = ()=>{
  return [
    generateColumn(
      t('member', 'Socio'),
      'member_name',
      '6%',
     false,
      sorterText('member_name')
    ),
    generateColumn(
      t('sale.titleID', 'Venta ID'),
      'sale_id',
      '5%',
      false,
      sorterText('sale_id')
    ),
    generateColumn(
      t('date.title', 'Fecha'),
      'sale_date',
      '5%',
      false,
      sorterText('sale_date')
    ),
    generateColumn(
      ('type', 'Tipo'),
      'type',
      '5%',
      (text) => <span>{text == 'sale' ? t('sales.title_singular', 'Venta') : text == 'credit_notes' ? t('sale.filter.creditNote', 'Nota de credito') : text == 'refunds' ? t('sale.filter.refund', 'Devolucion') : text == 'payments' ? t('payments.title_singular', 'Pago') : text == 'transaction' ? t('transactions.title', 'Transaccion') : text}</span>,
      sorterText('type')
    ),
    generateColumn(
      t('product_singular', 'Producto'),
      'description',
      '10%',
      false,
      sorterText('description')
    ),
    generateColumn(
      t('subtotal_singlular', 'Sub Total'),
      'subtotal',
      '7%',
      (text) => <span>{formatPrice(text)}</span>,
      sorterText('subtotal')
    ),
    generateColumn(
      t('discount', 'Descuento'),
      'discount_name',
      '8%',
      (text,r) =>r.discount_amount > 0&& <div className=''><span>{text}</span><Tag className='ml-2'  color={'green'} >{formatPrice(r.discount_amount)}</Tag></div>,
      sorterText('discount_name')
    ),
 
    generateColumn(
      t('total_singular', 'Total'),
      'total',
      '7%',
      (text) => <span>{formatPrice(text)}</span>,
      sorterText('total')
    ),
    generateColumn(
      t('seller', 'Vendedor'),
      'seller_name',
      '6%',
      false,
      sorterText('seller_name')
    ),
    generateColumn(
      t('sales.payment_method', 'Metodo de pago'),
      'payment_methods_name',
      '7%',
      false,
      sorterText('payment_methods_name')
    ),
    generateColumn(
      t('state_singular', 'Estado'),
      'operation_status',
      '6%',
      (text) => text&& <Tag color={text == 'approved' ? 'green' : text == 'pending' ? 'orange' : 'red'}>{text}</Tag>,
      sorterText('operation_status')
    ),
    generateColumn(
      t('detail', 'Detalle'),
      'details',
      '6%',
      false,
      sorterText('details')
    ),
    
    generateColumn(
      t('sales.operation_number', 'Número de operación'),
      'operation_number',
      '7%',
false,
      sorterText('operation_number')
    ),
  
    generateColumn(
      t('coach_singular', 'Coach'),
      'coach_name',
      '6%',
      false,
      sorterText('coach_name')
    ),
  ]
}
